import styled, { css } from 'styled-components';

const ContainerMain = styled.div`
  box-sizing: border-box;
  margin: ${(props) => props.theme.container.margin};
  padding: 36px 20px 0 20px;
  width: ${(props) => props.theme.container.width.xs};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 36px 0 0 0;
    width: ${(props) => props.theme.container.width.sm};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${(props) => props.theme.container.width.md};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: ${(props) => props.theme.container.width.lg};
  }
`;

const VisibilityContainer = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    ${(props) => props.xs && css`
      display: block;
    `}
  }

  @media (min-width: calc(${(props) => props.theme.breakpoints.sm} + 1px)) and (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => props.sm && css`
      display: block;
    `}
  }

  @media (min-width: calc(${(props) => props.theme.breakpoints.md} + 1px)) and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) => props.md && css`
      display: block;
    `}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) => props.lg && css`
      display: block;
    `}
  }

  ${(props) => props.isFullWidth && css`
    width: 100%;
  `}
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.secondary3Color};
  color: ${(props) => props.theme.primary1Color};
  text-transform: uppercase;
  border: 1px solid ${(props) => props.theme.primary1Color};
  outline: none;
  height: 38px;
  padding: 0 10px;
  font-weight: 700;
  cursor: pointer;
  transition: all ${(props) => props.theme.animationDuration};

  ${({ margin }) => margin && css`
    margin-right: 10px;
  `}

  * {
    color: ${(props) => props.theme.primary1Color};
    fill: ${(props) => props.theme.primary1Color};
  }

  &:hover {
    background-color: ${(props) => props.theme.primary1Color};

    * {
      color: ${(props) => props.theme.secondary3Color};
      fill: ${(props) => props.theme.secondary3Color};
    }
  }
`;

const PlayfairFontWrapper = styled.div`
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    src: url('/static/fonts/PlayfairDisplay-Black.tff') format()('truetype');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 900;
    src: url('/static/fonts/PlayfairDisplay-BlackItalic.tff') format()('truetype');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: bold;
    src: url('/static/fonts/PlayfairDisplay-Bold.tff') format()('truetype');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: bold;
    src: url('/static/fonts/PlayfairDisplay-BoldItalic.tff') format()('truetype');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: normal;
    src: url('/static/fonts/PlayfairDisplay-Regular.tff') format()('truetype');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: normal;
    src: url('/static/fonts/PlayfairDisplay-Italic.tff') format()('truetype');
  }
`;

export {
  PlayfairFontWrapper, ContainerMain, VisibilityContainer, Button,
};
