import React from 'react';
import PropTypes from 'prop-types';

import { HorizontalMenuItemShape } from 'components/Header/components/HorizontalMenu/HorizontalMenu.shapes';
import MenuLink from '../MenuLink';

import { Wrapper } from './Menu.styles';

const Menu = ({ menuItems, nested }) => (
  <Wrapper>
    {
      menuItems.filter((el) => !el.hidden).map((el, key) => (
        <MenuLink
          key={key}
          item={el}
          nested={nested}
        >
          {el.value}
        </MenuLink>
      ))
    }
  </Wrapper>
);

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(HorizontalMenuItemShape),
  nested: PropTypes.bool,
};

Menu.defaultProps = {
  menuItems: [],
  nested: false,
};

export default Menu;
