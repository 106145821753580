import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

const Link = ({
  icon, href, darkColor, smallIcon, theme, tracked,
}) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className={tracked ? 'yl-link' : ''}>
    {React.cloneElement(icon, {
      fill: darkColor ? theme.primary3Color : '#e5e8e9',
      height: smallIcon ? '18px' : '27px',
    })}
  </a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  darkColor: PropTypes.bool,
  smallIcon: PropTypes.bool,
  tracked: PropTypes.bool,
};

Link.defaultProps = {
  darkColor: false,
  smallIcon: false,
  tracked: false,
};

export default withTheme(Link);
