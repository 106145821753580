const headers = {
  h1: {
    fontSize: '34px',
    lineHeight: '54px',
    fontWeight: 400,
  },
  h2: {
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 400,
  },
  h3: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 400,
  },
  h4: {
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: 400,
  },
  h5: {
    fontSize: '16px',
    lineHeight: '30px',
    fontWeight: 700,
  },
  h6: {
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: 400,
  },
};

export default headers;
