import styled from 'styled-components';

const CookieConsentWrapper = styled.div`
  .CookieConsent {
    position: fixed;
    z-index: 9999;
    background-color: white;
    border-radius: 12px;
    color: black;
    box-shadow: 2px 2px 10px grey;
    max-width: 526px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    left: unset;
    right: 10px;
    box-sizing: border-box;
    transform: translateY(-10px);
    font-family: 'Montserrat',sans-serif;

    @media (max-width: 768px) {
      right: 0;
      transform: none;
      -webkit-tap-highlight-color: transparent;
      max-width: 100%;
      border-radius: 0;
    }

    .Wrapper {
        width: 100%;
        display: flex;
        button {
            flex: 1;
            border-radius: 6px;
            padding: 15px 10px;
            font-weight: 600;
            font-size: 15px;
            border: none;
            font-family: 'Montserrat',sans-serif;
            cursor: pointer;
        }

        .Accept {
            background-color: #e30613;
            color: white;
            margin-right: 5px;
        }

        .Properties {
            color: black;
            background-color: rgb(237 237 237);
            margin-left: 5px;
        }
    }

    h4 {
        font-size: 15px;
        text-align: center;
        font-weight: 600;
        margin-top: 5px;
    }

    p {
        font-size: 12px;
        font-weight: 500;
        word-wrap: break-word;

        a {
            text-decoration: none;
            font-weight: 600;
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 38px;
        height: 22px;
        }

        /* Hide default HTML checkbox */
        .switch input {
        opacity: 0;
        width: 0;
        height: 0;
        }

        /* The slider */
        .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        }

        .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        }

        input:checked + .slider {
        background-color: #e30613;
        }

        input:focus + .slider {
        box-shadow: 0 0 1px #e30613;
        }

        input:checked + .slider:before {
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
        }

        /* Rounded sliders */
        .slider.round {
        border-radius: 34px;
        }

        .slider.round:before {
        border-radius: 50%;
        }
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  CookieConsentWrapper,
  Options,
};
