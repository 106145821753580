import React from 'react';
import Magnifier from 'static/svg/magnifier.svg';
import MagnifierWhite from 'static/svg/magnifierWhite.svg';
import PropTypes from 'prop-types';

const MagnifierIcon = (props) => (
  props.white ? (<MagnifierWhite {...props} />) : (<Magnifier {...props} />)
);

MagnifierIcon.propTypes = {
  white: PropTypes.bool,
};

MagnifierIcon.defaultProps = {
  white: false,
};

export default MagnifierIcon;
