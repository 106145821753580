import React from 'react';
import PropTypes from 'prop-types';

import Slider from './components/Slider';
import {
  Wrapper, Container, Title, Row,
} from './RangeSlider.styles';

const RangeSlider = ({
  handleChange,
  firstDisplayValue,
  secondDisplayValue,
  title,
  formatDisplay,
  sliderMin,
  sliderMax,
  isWithoutExtraPadding,
  isDisabled,
}) => (
  <Wrapper disabled={isDisabled}>
    <Row>
      <Title>
        {title}
      </Title>
    </Row>
    <Container>
      <Slider
        handleChange={handleChange}
        minValue={sliderMin}
        maxValue={sliderMax}
        minSelected={firstDisplayValue}
        maxSelected={secondDisplayValue}
        isWithoutExtraPadding={isWithoutExtraPadding}
        formatDisplay={formatDisplay}
      />
    </Container>
  </Wrapper>
);

RangeSlider.propTypes = {
  sliderMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sliderMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  firstDisplayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatDisplay: PropTypes.string,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isWithoutExtraPadding: PropTypes.bool,
  secondDisplayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RangeSlider.defaultProps = {
  handleChange: () => {},
  firstDisplayValue: null,
  formatDisplay: null,
  secondDisplayValue: null,
  title: null,
  isWithoutExtraPadding: false,
  isDisabled: false,
};

export default RangeSlider;
