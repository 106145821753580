import React from 'react';
import PropTypes from 'prop-types';
import HorizontalMenuEntry from './components/HorizontalMenuEntry';
import { HorizontalMenuItemShape } from './HorizontalMenu.shapes';
import {
  Wrapper,
  ItemWrapper,
} from './HorizontalMenu.styles';

const HorizontalMenu = ({
  items,
}) => (
  <Wrapper>
    {
        items.filter((el) => !el.hidden).map((el, key) => {
          const isLast = key === items.length - 1;
          return (
            <ItemWrapper key={`horizontal-menu-item-${key}`}>
              <HorizontalMenuEntry {...el} last={isLast}>
                {el.value}
              </HorizontalMenuEntry>
            </ItemWrapper>
          );
        })
      }
  </Wrapper>
);

HorizontalMenu.propTypes = {
  items: PropTypes.arrayOf(HorizontalMenuItemShape),
};

HorizontalMenu.defaultProps = {
  items: [],
};

export default HorizontalMenu;
