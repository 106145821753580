import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'menu.home',
    defaultMessage: 'Home',
  },
  offer: {
    id: 'menu.offer',
    defaultMessage: 'Oferta',
  },
  search: {
    id: 'menu.search',
    defaultMessage: 'Lista mieszkań',
  },
  start: {
    id: 'menu.start',
    defaultMessage: 'Grupa start',
  },
  specialOffer: {
    id: 'menu.specialOffer',
    defaultMessage: 'Promocje',
  },
  clipboard: {
    id: 'menu.clipboard',
    defaultMessage: 'Koszyk',
  },
  blog: {
    id: 'menu.blog',
    defaultMessage: 'Blog',
  },
  news: {
    id: 'menu.news',
    defaultMessage: 'Aktualności',
  },
  contact: {
    id: 'menu.contact',
    defaultMessage: 'Kontakt',
  },
  investmentDescription: {
    id: 'menu.investmentDescription',
    defaultMessage: 'O inwestycji',
  },
  poi: {
    id: 'menu.poi',
    defaultMessage: 'Lokalizacja',
  },
  priceList: {
    id: 'menu.priceList',
    defaultMessage: 'Cennik',
  },
  constructionJournal: {
    id: 'menu.constructionJournal',
    defaultMessage: 'Dziennik budowy',
  },
  graphicSearch: {
    id: 'menu.graphicSearch',
    defaultMessage: 'Wyszukiwarka graficzna',
  },
  gridSearch: {
    id: 'menu.gridSearch',
    defaultMessage: 'Lista mieszkań',
  },
  gallery: {
    id: 'menu.gallery',
    defaultMessage: 'Galeria',
  },
  searchEngines: {
    id: 'menu.searchEngines',
    defaultMessage: 'Mieszkania',
  },
});
