import React from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { injectIntl } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import { Router } from 'Router';
import { Helmet } from 'react-helmet';
import config from 'config';

import Header from 'components/Header';
import HeaderMobile from 'components/HeaderMobile';
import Breadcrumbs from 'components/Breadcrumbs';
import ContactIcons from 'components/ContactIcons';
import Slider from 'components/Slider';
import QuickSearchForm from 'components/QuickSearchForm';
import ContactWithMap from 'components/ContactWithMap';
import FooterCopyright from 'components/FooterCopyright';
import GoToTop from 'components/GoToTop';
import translateMenu from 'utils/translateMenu';
import { VisibilityContainer } from 'utils/styles';
import intlShape from 'utils/shapes/intlShape';

import { SliderItemShape } from 'components/Slider/Slider.shapes';
import { MapPointShape } from 'components/GoogleMaps/GoogleMaps.shapes';
import { ContactUsEntryShape } from 'components/content/ContactUsBlock/ContactUsBlock.shapes';

import {
  HeaderWrapper,
  SearchWrapper,
  TopWrapper,
  ContactIconsWrapper,
  GoToTopWrapper,
  GlobalWrapper,
  ContentWrapper,
  IframeWrapper,
} from './PageLayout.styles';
import { socialLinks } from './PageLayout.data';
import { filterMapPoints } from './PageLayout.utils';
import { ContactShape } from './PageLayout.shapes';
import messages from './PageLayout.messages';

class PageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      isBottom: false,
      isSliderLoaded: false,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      const isBottom = window.scrollY > document.documentElement.scrollHeight - 800;

      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }

      if (isBottom !== this.state.isBottom) {
        this.setState({ isBottom });
      }
    });
  }

  onTopClick = () => {
    window.scrollTo(0, 0);
  };

  onSearchClick = (data) => {
    Router.push({
      pathname: '/wyszukiwarka',
      query: data,
      hash: 'scroll',
    });
  };

  onSliderLoaded = () => {
    this.setState({ isSliderLoaded: true });
  };

  render() {
    const {
      intl,
      breadcrumbs,
      children,
      contactItems,
      dronePictures,
      mapPoints,
      menu,
      contact,
      quickSearchFilters,
      hideQuickSearch,
      showContactData,
      sliderItems,
      staticMapUrl,
      showMap,
      hideBanner,
      hideFooter,
      favouritesCount,
      hideMobileBanner,
      onlyOfficesOnMap,
      contactPeople,
      onPositionChange,
      url,
      onImageLoad,
    } = { ...this.props };
    const translatedMenu = translateMenu(menu, intl);
    const pointsOnMap = onlyOfficesOnMap ? filterMapPoints({ mapPoints, type: 'Office' }) : mapPoints;
    let pageTitle = breadcrumbs.pageTitle ? `${breadcrumbs.pageTitle} | ${config.title}` : config.title;
    const pageDescription = breadcrumbs.pageDescription
      ? breadcrumbs.pageDescription : config.description;
    const phone = contact.phone;
    const isSliderPresent = !_isEmpty(sliderItems);

    const breadcrumb = () => (
      <Breadcrumbs
        key="breadcrumbs"
        {...breadcrumbs}
        onImageLoad={onImageLoad}
        isWithoutPathParts={breadcrumbs.isWithoutPathParts}
        isImageSmall={breadcrumbs.isImageSmall}
      />
    );

    const slider = () => (
      <Slider
        key="slider"
        items={sliderItems}
        onSliderLoaded={this.onSliderLoaded}
      />
    );

    if (breadcrumbs.pageTitle && breadcrumbs.isPageTitleRaw) {
      pageTitle = breadcrumbs.pageTitle;
    }

    if (!this.state.isSliderLoaded && isSliderPresent) {
      return slider();
    }

    return (
      <GlobalWrapper>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          { url && url.query && url.query.isNewest === 'false'
            ? <meta name="robots" content="noindex" /> : null }
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={breadcrumbs.imageUrl || config.metaOGImage} />
        </Helmet>
        <HeaderWrapper key="header">
          <VisibilityContainer md lg>
            <Header
              key="headerDesktop"
              menuItems={translatedMenu}
              socialLinksItems={socialLinks}
              favouritesCount={favouritesCount}
            />
          </VisibilityContainer>
          <VisibilityContainer xs sm>
            <HeaderMobile
              key="headerMobile"
              phone={phone}
              menuItems={translatedMenu}
              favouritesCount={favouritesCount}
              contactItems={contactItems}
              email={contact.mail}
              contactPeople={contactPeople}
            />
          </VisibilityContainer>
        </HeaderWrapper>
        {
          hideBanner ? null : (
            <VisibilityContainer md lg xs={!hideMobileBanner} sm={!hideMobileBanner}>
              <TopWrapper key="top">
                {
                  !isSliderPresent ? breadcrumb() : slider()
                }
              </TopWrapper>
              <VisibilityContainer md lg>
                {hideQuickSearch ? null : (
                  <SearchWrapper hasMarginTop={isSliderPresent}>
                    <QuickSearchForm
                      {...quickSearchFilters}
                      onButtonClick={this.onSearchClick}
                    />
                  </SearchWrapper>
                )}
              </VisibilityContainer>
            </VisibilityContainer>
          )}
        <ContentWrapper>
          {children}
        </ContentWrapper>
        {showMap ? (
          <ContactWithMap
            key="contactMap"
            contactItems={contactItems}
            mapPoints={pointsOnMap}
            staticMapUrl={staticMapUrl}
            showContactData={showContactData}
            centered={onlyOfficesOnMap}
            onPositionChange={onPositionChange}
          />
        ) : null}
        {dronePictures && (
        <IframeWrapper>
          <Iframe
            id={config.locationIds.investmentMap}
            title={intl.formatMessage(messages.panoramicMap)}
            src={dronePictures}
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
          />
        </IframeWrapper>
        )}
        {!hideFooter && (
        <FooterCopyright key="copyright" socialLinksItems={socialLinks} />
        )}
        <GoToTopWrapper isTop={this.state.isTop} isBottom={this.state.isBottom}>
          <GoToTop onClick={this.onTopClick} />
        </GoToTopWrapper>
        <ContactIconsWrapper key="contactIconsDesktop" center={!isSliderPresent}>
          <ContactIcons mail={contact.mail} phone={contact.phone} />
        </ContactIconsWrapper>
      </GlobalWrapper>
    );
  }
}

PageLayout.propTypes = {
  intl: intlShape.isRequired,
  breadcrumbs: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
  contact: ContactShape,
  contactItems: PropTypes.arrayOf(ContactUsEntryShape),
  dronePictures: PropTypes.node,
  footerMenu: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  hideBanner: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideMobileBanner: PropTypes.bool,
  hideQuickSearch: PropTypes.bool,
  imageLoaded: PropTypes.bool,
  mapPoints: PropTypes.arrayOf(MapPointShape),
  // eslint-disable-next-line react/forbid-prop-types
  menu: PropTypes.array,
  onlyOfficesOnMap: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  // eslint-disable-next-line react/forbid-prop-types
  quickSearchFilters: PropTypes.object,
  showContactData: PropTypes.bool,
  showMap: PropTypes.bool,
  sliderItems: PropTypes.arrayOf(SliderItemShape),
  staticMapUrl: PropTypes.string,
  onPositionChange: PropTypes.func,
};

PageLayout.defaultProps = {
  breadcrumbs: {},
  children: null,
  contact: {},
  contactItems: [],
  dronePictures: null,
  footerMenu: [],
  hideQuickSearch: false,
  mapPoints: [],
  menu: [],
  quickSearchFilters: {},
  showContactData: true,
  sliderItems: null,
  staticMapUrl: null,
  showMap: false,
  hideFooter: false,
  hideMobileBanner: false,
  hideBanner: false,
  onlyOfficesOnMap: false,
  onPositionChange: () => {},
  imageLoaded: false,
};

export default injectIntl(PageLayout);
