import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  & > div {
    display: flex;
    cursor: pointer;
  }
`;

const Expanded = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 300px;
  transform: translateY(100%);

  ${(props) => props.last && css`
    left: auto;
    right: 64px;
  `}
`;

export {
  Wrapper,
  Expanded,
};
