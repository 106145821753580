import * as Sentry from '@sentry/react';
import hash from 'object-hash';
import lscache from 'lscache';
import isArray from 'lodash/isArray';
import config from 'config';

const cacheRequest = (request, useCache, ...requestArgs) => {
  const requestID = hash(requestArgs);
  let cachedResponse = null;

  try {
    if (!useCache || typeof window === 'undefined') {
      return request(...requestArgs).then((res) => Promise.resolve({
        id: requestID,
        data: res,
      }));
    }

    cachedResponse = lscache.get(requestID);
  } catch (error) {
    Sentry.captureException(error);
    cachedResponse = null;
  }
  // If there is no cached response,
  // do the actual call and store the response
  if (cachedResponse === null) {
    return request(...requestArgs)
      .then((res) => {
        lscache.set(requestID, res, config.cache.lifetime);

        return Promise.resolve({
          id: requestID,
          data: res,
        });
      });
  }

  return Promise.resolve({
    id: requestID,
    data: cachedResponse,
  });
};

export function overrideCache(data) {
  try {
    const arrayedData = isArray(data) ? data : [data];

    arrayedData.forEach((el) => {
      if (el && el.id && el.data) {
        lscache.set(el.id, el.data, config.cache.lifetime);
      }
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

export default cacheRequest;
