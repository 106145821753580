import moment from 'moment';

const isPromotionActive = (dates) => {
  const currentDate = moment();
  const startDate = moment(dates.startDate, 'YYYY-MM-DD');
  const endDate = moment(dates.endDate, 'YYYY-MM-DD');

  if (currentDate.isBetween(startDate, endDate)) {
    return true;
  }
  return false;
};

export default isPromotionActive;
