import React from 'react';
import Link from 'components/ui/Link';
import PropTypes from 'prop-types';
import { FavouritesContainer, FavouritesCountContainer } from './Favourites.styles';
import HeartIcon from '../../../icons/Heart';

const Favourites = ({ favouritesCount, mobileVersion }) => (
  <FavouritesContainer mobileVersion={mobileVersion}>
    <Link route="clipboard" href="/clipboard">
      <HeartIcon />
      <FavouritesCountContainer mobileVersion={mobileVersion}>
        {favouritesCount}
      </FavouritesCountContainer>
    </Link>
  </FavouritesContainer>
);

Favourites.propTypes = {
  favouritesCount: PropTypes.number.isRequired,
  mobileVersion: PropTypes.bool,
};

Favourites.defaultProps = {
  mobileVersion: false,
};

export default Favourites;
