import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;

const ItemWrapper = styled.div`
  padding-top: 20px;
`;

export {
  Wrapper,
  ItemWrapper,
};
