import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'Router';

import { StyledLink } from './Link.styles';

const AliasLink = ({
  children,
  href,
  scroll,
  route,
  params,
  active,
  target,
  className,
  prettyPaths,
  draggable,
  ...props
}) => (
  <StyledLink {...props}>
    {active ? (
      <Link href={href} route={route} params={params} scroll={scroll}>
        <a target={target} className={className} draggable={draggable}>{children}</a>
      </Link>
    ) : children}
  </StyledLink>
);

AliasLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  prettyPaths: PropTypes.bool,
  route: PropTypes.string,
  scroll: PropTypes.bool,
  target: PropTypes.string,
};

AliasLink.defaultProps = {
  active: true,
  className: undefined,
  href: undefined,
  params: {},
  prettyPaths: true,
  route: undefined,
  scroll: true,
  target: undefined,
  draggable: true,
};

export default AliasLink;
