import React from 'react';
import PropTypes from 'prop-types';

import Link from './components/Link';

import { LinkShape } from './SocialLinks.shapes';

import { Wrapper, Item } from './SocialLinks.styles';

const SocialLinks = ({ items, darkColor, smallIcons }) => (
  <Wrapper>
    {
      items.map((el) => (
        <Item key={el.id} smallPadding={smallIcons}>
          <Link {...el} darkColor={darkColor} smallIcon={smallIcons} tracked />
        </Item>
      ))
    }
  </Wrapper>
);

SocialLinks.propTypes = {
  darkColor: PropTypes.bool,
  items: PropTypes.arrayOf(LinkShape),
  smallIcons: PropTypes.bool,
};

SocialLinks.defaultProps = {
  items: [],
  darkColor: false,
  smallIcons: false,
};

export default SocialLinks;
