import 'cross-fetch/polyfill';

async function getResult(response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.startsWith('application/json')) {
    return response.json();
  }
  return response.text();
}

const GraphQLRequest = async ({
  url,
  query,
  id,
  version,
  variables,
}) => {
  let body = '';

  if (id && version) {
    body = JSON.stringify({
      id,
      version,
      variables: variables || undefined,
    });
  } else {
    body = JSON.stringify({
      query,
      variables: variables || undefined,
    });
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });

  const result = await getResult(response);

  if (response.ok && !result.errors && result.data) {
    return result.data;
  }
  const errorResult = typeof result === 'string' ? { error: result } : result;

  throw new Error(JSON.stringify(errorResult));
};

export default GraphQLRequest;
