import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Wrapper } from './Box.styles';

const Box = ({ icon, theme }) => (
  <Wrapper>
    {React.cloneElement(icon, {
      fill: theme.primary2Color,
    })}
  </Wrapper>
);

Box.propTypes = {
  icon: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withTheme(Box);
