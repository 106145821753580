import React from 'react';
import PropTypes from 'prop-types';

import Box from './components/Box';
import {
  StyledLink, Description, Wrapper, StyledContainer,
} from './ContactIcon.styles';

const ContactIcon = ({
  href, onClick, icon, description,
}) => (
  <Wrapper>
    {onClick ? (
      <StyledContainer onClick={onClick}>
        <Box icon={icon} />
        <Description>{description}</Description>
      </StyledContainer>
    ) : (
      <StyledLink route={href}>
        <Box icon={icon} />
        <Description>{description}</Description>
      </StyledLink>
    )}
  </Wrapper>
);

ContactIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  description: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

ContactIcon.defaultProps = {
  description: null,
  href: null,
  onClick: () => {},
};

export default ContactIcon;
