import React from 'react';
import PropTypes from 'prop-types';
import { VisibilityContainer } from 'utils/styles';

import {
  Wrapper, BoldText, LightText, TopHeader, SuperLightText,
} from './SectionHeader.styles';

const SectionHeader = ({
  black,
  bold,
  boxPosition,
  children,
  light,
  lightTextColor,
  longBox,
  narrowBox,
  size,
  uppercase,
  alignCenter,
  boldText,
  lightText,
  superLightText,
  topHeaderDescriptionText,
  alignTextLeft,
}) => (
  <Wrapper
    boxPosition={boxPosition}
    uppercase={uppercase}
    size={size}
    longBox={longBox}
    narrowBox={narrowBox}
    bold={bold}
    black={black}
    light={light}
    lightTextColor={lightTextColor}
    alignCenter={alignCenter}
    alignTextLeft={alignTextLeft}
  >
    {topHeaderDescriptionText && (
    <VisibilityContainer md lg>
      <TopHeader>
        {topHeaderDescriptionText}
      </TopHeader>
    </VisibilityContainer>
    )}
    {boldText && <BoldText>{boldText}&nbsp;</BoldText>}
    {lightText && <LightText>{lightText}</LightText>}
    {superLightText && <SuperLightText>{superLightText}</SuperLightText>}
    {children}
  </Wrapper>
);

SectionHeader.propTypes = {
  alignCenter: PropTypes.bool,
  alignTextLeft: PropTypes.bool,
  black: PropTypes.bool,
  bold: PropTypes.bool,
  boldText: PropTypes.string,
  boxPosition: PropTypes.oneOf(['left', 'bottom', 'bottom-left']),
  children: PropTypes.node,
  light: PropTypes.bool,
  lightText: PropTypes.string,
  lightTextColor: PropTypes.bool,
  longBox: PropTypes.bool,
  narrowBox: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  superLightText: PropTypes.string,
  topHeaderDescriptionText: PropTypes.string,
  uppercase: PropTypes.bool,
};

SectionHeader.defaultProps = {
  bold: false,
  black: false,
  boxPosition: 'left',
  light: false,
  lightTextColor: false,
  longBox: false,
  narrowBox: false,
  size: 'small',
  uppercase: true,
  alignCenter: false,
  boldText: '',
  lightText: '',
  superLightText: '',
  topHeaderDescriptionText: '',
  alignTextLeft: false,
  children: null,
};

export default SectionHeader;
