import React from 'react';
import SmoothCollapse from 'react-smooth-collapse';

import Link from 'components/ui/Link';
import { HorizontalMenuItemShape } from 'components/Header/components/HorizontalMenu/HorizontalMenu.shapes';
import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import Menu from '../Menu';

import {
  Wrapper,
  LinkItem,
  ExpanderLinkItem,
  ExpanderItem,
  MenuWrapper,
} from './MenuLink.styles';

class MenuLink extends React.Component {
  static propTypes = {
    item: HorizontalMenuItemShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { opened: false };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ opened: !prevState.opened }));
  }

  render() {
    let output = null;

    if (this.props.item.items) {
      output = (
        <React.Fragment>
          <ExpanderItem onClick={this.toggleMenu} active={this.state.opened}>
            <ExpanderLinkItem nested={this.props.nested}>
              {this.props.item.value}
            </ExpanderLinkItem>
          </ExpanderItem>
          <SmoothCollapse expanded={this.state.opened}>
            <MenuWrapper>
              <Menu menuItems={this.props.item.items} nested />
            </MenuWrapper>
          </SmoothCollapse>
        </React.Fragment>
      );
    } else {
      output = (
        <LinkItem
          nested={this.props.nested}
          onClick={
          () => trackYouleadVirtualPageView(this.props.item.params && this.props.item.params.id
            ? `${this.props.item.params.id}${this.props.item.value}` : this.props.item.value)
        }
        >
          <Link {...this.props.item}>
            {this.props.item.value}
          </Link>
        </LinkItem>
      );
    }
    return (
      <Wrapper>
        {output}
      </Wrapper>
    );
  }
}


export default MenuLink;
