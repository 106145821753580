import PropTypes from 'prop-types';

export const PathPartShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
});

export const BreadcrumbsShape = PropTypes.shape({
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  pathParts: PropTypes.arrayOf(PathPartShape),
});
