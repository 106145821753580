import styled from 'styled-components';

const OptionWrapper = styled.div`
  background-color: rgb(237 237 237);
  border-radius: 6px;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  .details {
    display: flex;
    justify-content: space-between;
        
    span {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }

    .set {
      display: flex;
      align-items: center;
    }

    .major {
      font-size: 12px;
      color: green;
    }

    .arrow {
      margin: 5px;
      transform: rotate(90deg);
      cursor: pointer;
      transition: transform 200ms;
      &.clicked {
        transform: rotate(270deg);
      }
    }
`;

export {
  OptionWrapper,
};
