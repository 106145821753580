import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import SubmenuLink from './components/SubmenuLink';

import { MenuItemShape } from './SubmenuBox.shapes';
import { MenuWrapper, Wrapper, EntryContainer } from './SubmenuBox.styles';

class SubmenuBox extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(MenuItemShape).isRequired,
    topBorder: PropTypes.bool,
  };

  static defaultProps = {
    topBorder: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      expandedMenuIndex: null,
    };

    this.mainMenuWidth = null;
  }

  onHover = (index) => this.setState({ expandedMenuIndex: index })

  outHover = () => this.setState({ expandedMenuIndex: null })

  isHoverable = (index) => !isEmpty(this.props.items[index])
    && !isEmpty(this.props.items[index].items)

  render() {
    return (
      <MenuWrapper topBorder={this.props.topBorder}>
        <Wrapper>
          {
            this.props.items.filter((el) => !el.hidden).map((el, key) => (
              <EntryContainer
                key={`menu-item-${key}`}
                onClick={() => this.onHover(key)}
              >
                <SubmenuLink
                  showArrow={false}
                  href={
                    this.isHoverable(key) ? '' : el.href
                  }
                  route={
                    this.isHoverable(key) ? '' : el.route
                  }
                  params={el.params}
                  scroll={el.scroll}
                  active={key === this.state.expandedMenuIndex}
                  trackPage={trackYouleadVirtualPageView}
                >
                  {el.value}
                </SubmenuLink>
              </EntryContainer>
            ))
          }
        </Wrapper>
        {
          !isEmpty(this.props.items[this.state.expandedMenuIndex])
          && !isEmpty(this.props.items[this.state.expandedMenuIndex].items) ? (
            <Wrapper
              fullWidth
              onClick={() => this.onHover(this.state.expandedMenuIndex)}
            >
              {
                this.props.items[this.state.expandedMenuIndex].items
                  .filter((el) => !el.hidden)
                  .map((el, key) => (
                    <div key={`menu-item-${key}`}>
                      <SubmenuLink
                        href={el.href}
                        route={el.route}
                        params={el.params}
                        scroll={el.scroll}
                        trackPage={trackYouleadVirtualPageView}
                      >
                        {el.value}
                      </SubmenuLink>
                    </div>
                  ))
              }
            </Wrapper>
            ) : null
        }
      </MenuWrapper>
    );
  }
}

export default SubmenuBox;
