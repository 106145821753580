import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { push as PushMenu } from 'react-burger-menu';

import { SCROLLABLE_ELEMENT_CLASS_NAME } from 'utils/bodyScrollLock';
import { Router } from 'Router';


import { HorizontalMenuItemShape } from 'components/Header/components/HorizontalMenu/HorizontalMenu.shapes';
import FooterCopyright from 'components/FooterCopyright/FooterCopyright';
import Menu from './components/Menu';

import {
  Wrapper, MenuWrapper, FooterContainer, MenuContentWrapper,
} from './BurgerMenu.styles';
import SearchButton from '../SearchButton/SearchButton';

const BurgerMenu = ({
  opened, onStateChange, menuItems, toggleBurgerMenu,
}) => {
  useEffect(() => {
    if (opened) {
      toggleBurgerMenu();
    }
  }, [Router.router ? Router.router.asPath : undefined, typeof window !== 'undefined' ? window.location.hash : '']);

  return (
    <Wrapper active={opened}>
      <PushMenu right onStateChange={onStateChange} width="100%" noOverlay isOpen={opened}>
        <MenuWrapper>
          <SearchButton />
          <MenuContentWrapper className={SCROLLABLE_ELEMENT_CLASS_NAME}>
            <Menu menuItems={menuItems} />
          </MenuContentWrapper>
          <FooterContainer active={opened}>
            <FooterCopyright />
          </FooterContainer>
        </MenuWrapper>
      </PushMenu>
    </Wrapper>
  );
};

BurgerMenu.propTypes = {
  toggleBurgerMenu: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(HorizontalMenuItemShape),
  opened: PropTypes.bool,
};

BurgerMenu.defaultProps = {
  menuItems: [],
  opened: false,
};

export default BurgerMenu;
