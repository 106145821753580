import { defineMessages } from 'react-intl';

export default defineMessages({
  all: {
    id: 'Select.all',
    defaultMessage: '- Dowolne - ',
  },
  noOptions: {
    id: 'Select.noOptions',
    defaultMessage: 'Brak opcji',
  },
});
