import 'cross-fetch/polyfill';
import { RequestError } from 'utils/errors/RequestError';

async function getResult(response) {
  if (response.status >= 400) throw new RequestError({ status: response.status });

  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.startsWith('application/json')) {
    return response.json();
  }
  return response.text();
}

const ApiRequest = async ({
  url,
  method,
  query,
}) => {
  const body = JSON.stringify(query);

  const response = await fetch(url, {
    method,
    headers: { 'Content-Type': 'application/json' },
    body,
  });

  const result = await getResult(response);

  return result;
};

export default ApiRequest;
