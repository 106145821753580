import PropTypes from 'prop-types';

export const MenuItemShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  route: PropTypes.string,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
});
