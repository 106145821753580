import PropTypes from 'prop-types';

const UrlDataShape = PropTypes.shape({
  title: PropTypes.string,
  image: PropTypes.string,
});

const InvestmentDataShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.string,
});

const ContactShape = PropTypes.shape({
  mail: PropTypes.string,
  phone: PropTypes.string,
});

export { UrlDataShape, InvestmentDataShape, ContactShape };
