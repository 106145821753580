import { defineMessages } from 'react-intl';

export default defineMessages({
  copyright: {
    id: 'FooterCopyright.copyright',
    defaultMessage: `Copyright © ${new Date().getFullYear()} - Grupa Deweloperska START`,
  },
  information: {
    id: 'FooterCopyright.information',
    defaultMessage: 'Informacje Prawne',
  },
});
