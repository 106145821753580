import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyHeader: {
    id: 'Cookies.privacyHeader',
    defaultMessage: 'Szanujemy Twoją Prywatność',
  },
  privacyText: {
    id: 'Cookies.privacyText',
    defaultMessage: 'Aby zapewnić jak najlepsze wrażenia, korzystamy z technologii, takich jak pliki cookie, do przechowywania i/lub uzyskiwania dostępu do informacji o urządzeniu. Nasi zaufani partnerzy zbierają dane i używają plików cookie do personalizacji reklam i mierzenia ich skuteczności a pliki cookie mogą być wykorzystywane do spersonalizowanych jak i niespersonalizowanych reklam. Używając przycisku Akceptuję wyrażasz zgodę na korzystanie z technologii takich jak cookie przez nas i naszych zaufanych partnerów. Szanując Twoją prywatność szczegółowe informacje na ten temat zawarliśmy w {privacyPolicy} i {cookiesPolicy}. W każdej chwili możesz zmienić swoje preferencje używając przycisku Zobacz preferencje.',
  },
  functional: {
    id: 'Cookies.functional',
    defaultMessage: 'Funkcjonalne',
  },
  statistic: {
    id: 'Cookies.statistic',
    defaultMessage: 'Statystyczne',
  },
  marketing: {
    id: 'Cookies.marketing',
    defaultMessage: 'Marketing',
  },
  functionalText: {
    id: 'Cookies.functionalText',
    defaultMessage: 'Przechowywanie lub dostęp do danych technicznych jest ściśle konieczny do uzasadnionego celu umożliwienia korzystania z konkretnej usługi wyraźnie żądanej przez subskrybenta lub użytkownika, lub wyłącznie w celu przeprowadzenia transmisji komunikatu przez sieć łączności elektronicznej.',
  },
  statisticText: {
    id: 'Cookies.statisticText',
    defaultMessage: 'Przechowywanie techniczne lub dostęp, który jest używany wyłącznie do celów statystycznych.',
  },
  marketingText: {
    id: 'Cookies.marketingText',
    defaultMessage: 'Przechowywanie lub dostęp techniczny jest wymagany do tworzenia profili użytkowników w celu wysyłania reklam lub śledzenia użytkownika na stronie internetowej lub na kilku stronach internetowych w podobnych celach marketingowych.',
  },
  acceptBtn: {
    id: 'Cookies.acceptBtn',
    defaultMessage: 'Akceptuję',
  },
  propertiesBtn: {
    id: 'Cookies.acceptBtn',
    defaultMessage: 'Zobacz preferencje',
  },
  privacyLink: {
    id: 'Cookies.privacyLink',
    defaultMessage: 'Polityce Prywatności',
  },
  cookiesLink: {
    id: 'Cookies.cookiesLink',
    defaultMessage: 'Polityce Plików Cookies',
  },
});
