import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  margin-top: 50px;
  position: relative;

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 35px;
    height: 32px;
    right: 18px;
    top: 8px;
    padding: 9px 9px 9px;
    box-sizing: border-box;
    border: none;
    background: ${(props) => props.theme.secondary15Color};
    border-radius: 0;
    transition: 0.2s ease all;

    & > span {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #000;
    opacity: 1 !important;
    height: 2px !important;
    transition: 0.2s ease all;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 32px !important;
    width: 32px !important;
    top: 8px !important;
    right: 20px !important;
    background: ${(props) => props.theme.primary3Color};
    display: none;

    > span {
      width: 3px !important;
      height: 28px !important;
      top: 4px;
    }
  }

  /* Color/shape of close button cross */
  .bm-cross {
    width: 1.58px !important;
    height: 23px !important;
    background: ${(props) => props.theme.secondary7Color};
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${(props) => props.theme.primary2Color};
    font-size: 1.15em;
    height: calc(100% - 50px) !important;
    overflow: hidden !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: ${(props) => props.theme.primary3Color};
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: ${(props) => props.theme.primary3Color};
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    margin-top: 0;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  ${({ active }) => active && css`
     .bm-burger-button {
       background: ${(props) => props.theme.secondary12Color};
       padding: 9px 9px 9px 8px;
       .bm-burger-bars {
         background: ${(props) => props.theme.secondary8Color};

         &:nth-child(1) {
           transform: rotateZ(45deg);
           width: 20px !important;
           top: 5px !important;
         }
         &:nth-child(2) {
           transform: rotateZ(-45deg);
           width: 20px !important;
         }
         &:nth-child(3) {
           display: none;
         }
       }
     }
  `}
`;

const Header = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  background-color: ${(props) => props.theme.primary2Color};
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;
`;

const MenuContentWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const MenuWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  margin-top: 50px;

  height: 100%;

  & > div {
    height: unset;
  }
`;

const FooterContainer = styled.div`
  width: 100vw;
`;

export {
  Wrapper,
  Header,
  MenuWrapper,
  FooterContainer,
  MenuContentWrapper,
};
