import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import FullArrow from 'components/icons/FullArrow';
import { Button } from './GoToTop.styles';

const GoToTop = ({ onClick, theme }) => (
  <Button onClick={onClick}>
    <FullArrow fill={theme.primary2Color} />
  </Button>
);

GoToTop.propTypes = {
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func,
};

GoToTop.defaultProps = {
  onClick: () => {},
};

export default withTheme(GoToTop);
