import React from 'react';
import { Link } from 'Router';

import LogoImage from 'static/svg/logo.svg';
import { Wrapper } from './Logo.styles';

const Logo = () => (
  <Wrapper>
    <Link route="/">
      <LogoImage />
    </Link>
  </Wrapper>
);

export default Logo;
