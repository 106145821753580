import styled, { css, injectGlobal } from 'styled-components';
import { ContainerMain } from 'utils/styles';
import breakpoints from 'theme/breakpoints';
import config from 'config';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  body {
    min-width: 360px;
    overflow-x: auto;
    height: 100vh;
  }

  html {
  overflow-y: initial !important;
    @media (min-width: ${breakpoints.sm}) {
      overflow-x: initial !important;
    }
  }

  #__next {
    height: 100%;
  }

  .${config.openedModalClassName} {
    overflow: hidden;
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 101;
`;

const TopWrapper = styled.div`
  position: relative;
  z-index: 0;
  background-color: ${(props) => props.theme.sliderBackgroundColor};
`;

const SearchWrapper = styled(ContainerMain)`
  z-index: 100;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: -79px;
  }
`;

const ContactIconsWrapper = styled.div`
  width: 100%;
  max-width: ${config.sliderWidth.value}${config.sliderWidth.unit};
  display: none;
  pointer-events: none;
  transform-origin: right;
  top: 31.5%;
  z-index: 120;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: initial;
    transform: translateY(-50%) scale(0.7);

    ${(props) => props.center && css`
      top: 31.5%;
      transform: translateY(-50%);
    `}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    transform: translateY(-50%);
  }
`;

const GoToTopWrapper = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  opacity: ${(props) => (props.isTop ? 0 : 1)};
  transition: 0.3s all ease;
  z-index: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    right: 12px;

    ${({ isBottom }) => isBottom && css`
      bottom: 90px;
    `}
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;

const GlobalWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  a::-moz-focus-inner,
  svg::-moz-focus-inner,
  img::-moz-focus-inner,
  button::-moz-focus-inner,
  a:focus,
  svg:focus,
  img:focus,
  button:focus {
    border: 0;
    outline: 0;
  }
`;

const IframeWrapper = styled.div`
  width: 100%;
  height: 580px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 60vh;
  }

  & > iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

export {
  GlobalWrapper,
  HeaderWrapper,
  TopWrapper,
  SearchWrapper,
  ContactIconsWrapper,
  GoToTopWrapper,
  ContentWrapper,
  IframeWrapper,
};
