import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'breadcrumbs.home',
    defaultMessage: 'Home',
  },
  contact: {
    id: 'breadcrumbs.contact',
    defaultMessage: 'Kontakt',
  },
  poi: {
    id: 'breadcrumbs.poi',
    defaultMessage: 'Lokalizacja',
  },
  priceList: {
    id: 'breadcrumbs.priceList',
    defaultMessage: 'Cennik',
  },
  newsList: {
    id: 'breadcrumbs.newsList',
    defaultMessage: 'Aktualności',
  },
  customerOpinions: {
    id: 'breadcrumbs.customerOpinions',
    defaultMessage: 'Opinie klientów',
  },
  investmentList: {
    id: 'breadcrumbs.investmentList',
    defaultMessage: 'Inwestycje zrealizowane',
  },
  mediaAboutUs: {
    id: 'breadcrumbs.mediaAboutUs',
    defaultMessage: 'Media o nas',
  },
});
