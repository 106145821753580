import isArray from 'lodash/isArray';
import moment from 'moment';

export const getNode = (data, key = 'nodeQuery') => data[key].entities;

export const image = (data) => (data ? data.entity.url : null);

export const imageStyle = (data) => (data ? data.derivative.url : null);

export const timestamp = (data, seconds = false) => {
  if (!data) {
    return null;
  }

  const milliseconds = moment(data, 'YYYY-MM-DD LTS ZZ').valueOf();

  if (seconds) {
    return milliseconds / 1000;
  }

  return milliseconds;
};

export const value = (data) => (data ? data.value : null);

export const name = (data) => (data ? data.entity.name : null);

export const url = (data) => (data ? data.url : null);

export const link = (data) => {
  const params = {};

  if (isArray(data.entity.params)) {
    data.entity.params.forEach((el) => {
      params[el.name] = el.value;
    });
  }

  return {
    value: data.entity.value,
    href: data.entity.href,
    route: data.entity.route,
    params,
  };
};
