import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/ui/Link';
import MenuArrow from 'components/icons/MenuArrow';

import { Wrapper } from './SubmenuLink.styles';

const SubmenuLink = ({
  children,
  showArrow,
  href,
  scroll,
  route,
  params,
  active,
  trackPage,
}) => (
  <Link href={href} active={href !== ''} route={route} params={params} scroll={scroll} onClick={href !== '' ? () => trackPage(params.id ? `${params.id}/${route}` : route) : null}>
    <Wrapper active={active}>
      {children}
      {showArrow ? <MenuArrow width={8} height={8} /> : null }
    </Wrapper>
  </Link>
);

SubmenuLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  active: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  route: PropTypes.string,
  scroll: PropTypes.bool,
  showArrow: PropTypes.bool,
  trackPage: PropTypes.func,
};

SubmenuLink.defaultProps = {
  scroll: true,
  showArrow: true,
  href: undefined,
  params: {},
  route: undefined,
  active: false,
  trackPage: () => {},
};

export default SubmenuLink;
