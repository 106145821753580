import styled, { css } from 'styled-components';

const StyledLink = styled.div`
  height: 100%;

  ${(props) => props.height !== undefined && css`
    height: ${props.height};
  `}

  a {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: ${(props) => props.color || props.theme.primary3Color};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: auto;
  }
`;

export { StyledLink };
