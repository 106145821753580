import _find from 'lodash/find';
import config from 'config';
import Cookies from 'js-cookie';

function getUpdatedCookies(shared) {
  const clipboardItems = Cookies.getJSON('clipboard') ? Cookies.getJSON('clipboard') : [];
  const updatedClipboardItems = [];

  for (let i = 0; i < clipboardItems.length; i += 1) {
    if (_find(shared.items, ['id', clipboardItems[i]])) {
      updatedClipboardItems.push(clipboardItems[i]);
    }
  }

  if (Cookies.get(config.statisticCookies) === 'true') {
    Cookies.set('clipboard', updatedClipboardItems, { expires: config.cookie.daysToExpire });
  }

  return updatedClipboardItems.length;
}

export default getUpdatedCookies;
