import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  border: none;
`;

const LinkItem = styled.div`
  padding: 10px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
`;

export { Wrapper, LinkItem };
