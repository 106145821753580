import messages from './translateMenu.messages';

const translateMenu = (menu, intl) => {
  const newMenu = menu.map((el) => {
    let items;
    if (el.items) {
      items = translateMenu(el.items, intl);
    }

    return ({
      ...el,
      translatable: false,
      value: el.translatable ? intl.formatMessage(messages[el.value]) : el.value,
      items,
    });
  });

  return newMenu;
};

export default translateMenu;
