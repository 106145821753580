import React from 'react';
import PropTypes from 'prop-types';
import { VisibilityContainer } from 'utils/styles';
import Logo from './components/Logo';
import HorizontalMenu from './components/HorizontalMenu';
import { HorizontalMenuItemShape } from './components/HorizontalMenu/HorizontalMenu.shapes';
import Favourites from './components/Favourites/Favourites';

import {
  FixedWrapper,
  HeaderPlaceholder,
  Wrapper,
  LogoWrapper,
  MenuWrapper,
} from './Header.styles';

const Header = ({ menuItems, favouritesCount }) => (
  <React.Fragment>
    <FixedWrapper>
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <MenuWrapper>
          <HorizontalMenu items={menuItems} />
          <VisibilityContainer md lg>
            <Favourites favouritesCount={favouritesCount} />
          </VisibilityContainer>
        </MenuWrapper>
      </Wrapper>
    </FixedWrapper>
    <HeaderPlaceholder />
  </React.Fragment>
);

Header.propTypes = {
  favouritesCount: PropTypes.number.isRequired,
  menuItems: PropTypes.arrayOf(HorizontalMenuItemShape),
};

Header.defaultProps = {
  menuItems: [],
};

export default Header;
