import React from 'react';
import PropTypes from 'prop-types';
import { OptionWrapper } from './Option.styles';
import ArrowIcon from '../icons/Arrow';

class Option extends React.Component {
  static propTypes = {
    detailsText: PropTypes.node.isRequired,
    name: PropTypes.node.isRequired,
    alwaysActive: PropTypes.bool,
    checked: PropTypes.bool,
    cookiesToggle: PropTypes.func,
    labelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    checked: true,
    cookiesToggle: undefined,
    alwaysActive: false,
    labelId: undefined,
  };

  state = {
    detailsShow: false,
  }

  detailsToggle = () => {
    this.setState((prevState) => ({ detailsShow: !prevState.detailsShow }));
  }

  render() {
    return (
      <OptionWrapper>
        <div className="details">
          <span>{this.props.name}</span>
          <div className={this.props.alwaysActive ? 'alwaysActive' : 'configurable'}>
            <div className="set">
              {this.props.alwaysActive ? (
                <span className="major">Zawsze aktywne</span>
              ) : (
                <label className="switch" htmlFor={this.props.labelId}>
                  <input type="checkbox" defaultChecked={this.props.checked} onChange={this.props.cookiesToggle} />
                  <span className="slider round" />
                </label>
              )}
              <ArrowIcon onClick={this.detailsToggle} className={this.state.detailsShow ? 'arrow clicked' : 'arrow'} />
            </div>
          </div>
        </div>
        {this.state.detailsShow && (<p>{this.props.detailsText}</p>)}
      </OptionWrapper>
    );
  }
}

export default Option;
