import styled from 'styled-components';


const Wrapper = styled.div`
  background-color: ${(props) => props.theme.primary4Color};
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 45px;
    height: 44px;
    opacity: 0.8;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 60px;
    height: 57px;
  }
`;

export { Wrapper };
