import breakpoints from './breakpoints';
import container from './container';
import headers from './headers';
import variables from './variables';

const theme = {
  breakpoints,
  container,
  headers,
  ...variables,
};

export default theme;
