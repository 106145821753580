import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutUsTitle: {
    id: 'PageLayout.footer.aboutUsTitle',
    defaultMessage: 'O nas',
  },
  aboutUsDescription: {
    id: 'PageLayout.footer.aboutUsDescription',
    defaultMessage: '<p>Przedstawione na stronie internetowej www.start.com.pl wizualizacje, animacje oraz modele budynków maja charakter poglądowy i nie stawią oferty ani zapewnienia w rozumieniu Kodeksu Cywilnego. Wygląd budynków oraz zagospodarowanie terenu mogą ulec zmianie na etapie realizacji. Wszelkie informacje i dane zawarte na niniejszej stronie internetowej podlegają ochronie praw autorskich, zgodnie z ustawą z dnia 4 lutego 1994 r. o Prawie autorskim i prawach pokrewnych (t.j. Dz. U. 2018 poz. 1191 z późn. zm.). Wykorzystywanie danych lub materiałów z niniejszej strony w jakichkolwiek celu wymaga każdorazowo pisemnej zgody P. B. START G.Szmolke, M.Szmolke sp.j. z siedzibą w Krakowie przy ul. Twardowskiego 65, REGON 357074795, NIP 6792585656, KRS 0000153221.</p>',
  },
  offerTitle: {
    id: 'PageLayout.footer.offerTitle',
    defaultMessage: 'Oferta',
  },
  startTitle: {
    id: 'PageLayout.footer.startTitle',
    defaultMessage: 'Grupa Start',
  },
  informationsTitle: {
    id: 'PageLayout.footer.informationsTitle',
    defaultMessage: 'Informacje',
  },
  m2: {
    id: 'PageLayout.search.m2',
    defaultMessage: 'm²',
  },
  panoramicMap: {
    id: 'PageLayout.panoramicMap',
    defaultMessage: 'Mapa Panoramiczna',
  },
});
