const nextRoutes = require('next-routes');

const routes = nextRoutes();

routes.add('virtualMock', '/:id/wirtualna-makieta');
routes.add('virtualPresentation', '/wirtualna-prezentacja');
routes.add('aboutUs', '/o-nas');
routes.add('turnkeyFinishes', '/wykonczenia-pod-klucz');
routes.add('staticPage', '/static-page/:id');
routes.add('legalInformation', '/prawne');
routes.add('newsListPage', '/lista-aktualnosci/:page', 'newsList');
routes.add('newsList', '/lista-aktualnosci');
routes.add('blog', '/blog/:page');
routes.add('blogMain', '/blog', 'blog');
routes.add('blogPost', '/blog/post/:id');
routes.add('promotionLocalsList', '/lista-lokali-promocyjnych/:promotionId');
routes.add('promotionsList', '/promocje/:page');
routes.add('newsSingle', '/aktualnosci/:id/:newest');
routes.add('clipboard', '/ulubione');
routes.add('contact', '/kontakt', 'salesDepartment');
routes.add('contactForm', '/kontakt#form', 'salesDepartment');
routes.add('constructionJournalList', '/dziennik-budowy/:id');
routes.add('customerOpinionList', '/opinie-klientow');
routes.add('mediaAboutUsList', '/media-o-nas');
routes.add('inwestycje-zrealizowane', '/inwestycje-zrealizowane', 'inwestycje-zrealizowane');
routes.add('wyszukiwarka-inwestycji', '/wyszukiwarka-inwestycji/:investmentSlug/:buildingSlug', 'wyszukiwarka-inwestycji');
routes.add('local', '/:investmentId/:id', 'local');
routes.add('localToScroll', '/:investmentId/:id#scroll', 'local');
routes.add('investmentBuildingSearch', '/investment/:id/buildings');
routes.add('investmentFloorSearch', '/investment/:investmentId/building/:id');
routes.add('investmentLocalSearch', '/investment/:investmentId/building/:id/floors/:level');
routes.add('investmentGridSearch', '/:id/grid-search/:page');
routes.add('investmentTableSearch', '/:id/table-search');
routes.add('poi', '/:id/mapa');
routes.add('priceList', '/:id/cennik');
routes.add('wyszukiwarka', '/wyszukiwarka');
routes.add('collaboration', '/wspolpraca');
routes.add('buyingAssets', '/kupimy-grunty');
routes.add(
  'investmentDescriptionDetails',
  '/:id#o-inwestycji',
  'investmentDescription',
);
routes.add(
  'investmentDescriptionGallery',
  '/:id#galeria',
  'investmentDescription',
);
routes.add(
  'investmentDescriptionSearch',
  '/:id#mieszkania',
  'investmentDescription',
);
routes.add(
  'investmentDescriptionLocalization',
  '/:id#mapa-inwestycji',
  'investmentDescription',
);
routes.add('investmentDescription', '/:id', 'investmentDescription');
routes.add('surveySatisfaction', '/ask');
routes.add('surveyComplaint', '/opr');
routes.add('surveyMarketing', '/ar');
routes.add('unsubscribe', '/unsubscribe');

module.exports = routes;
