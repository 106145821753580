import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;

  div + & {
    border-top: 1px solid #c4c4c4;
  }
`;

const LinkItem = styled.div`
  padding: 20px 10px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;

  > div {
    height: 100%;
    width: 100%;

    a {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
    letter-spacing: 1.1px;
    font-weight: ${(props) => (props.nested ? 600 : 400)};
    padding: 0 45px;
    height: 50px;
    display: flex;
    align-items: center;

    &:focus {
      background: ${(props) => props.theme.secondary7Color};
    }
  }
`;

const ExpanderLinkItem = styled(LinkItem)`
  width: calc(100% - 50px);
  text-align: left;
  border-left: 0;
  font-weight: ${(props) => (props.nested ? 600 : 400)};
`;

const ExpanderItem = styled.button`
  background-color: ${(props) => (props.active ? props.theme.secondary8Color : 'transparent')};
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  font-weight: 500;
  color: ${(props) => (props.active ? props.theme.primary2Color : props.theme.primary3Color)};
`;

const ExpanderWrapper = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuWrapper = styled.div`
  padding-left: 20px;
  border-top: 1px solid #c4c4c4;
`;

export {
  Wrapper, LinkItem, ExpanderLinkItem, ExpanderItem, ExpanderWrapper, MenuWrapper,
};
