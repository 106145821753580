import { createSelector } from 'reselect';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import config from 'config';
import { populateMenu, pushUniqueElement } from './shared.utils';
import getUpdatedCookies from '../utils/getUpdatedCookies';

const SharedSelector = (state) => state.Shared;
export const ContactItems = (state) => state.Shared.contactItems;
export const QuerySelector = (state, props) => props.url.query;

export const InvestmentsSelector = (state) => state.Shared.investments;
const SharedMenuSelector = (state) => state.Shared.menu;
export const itemsSelector = (state) => state.Shared.items.filter((el) => el.nr);

export const ItemsToSellSelector = createSelector(
  itemsSelector,
  (items) => items.filter((el) => el.full),
);

export const InvestmentsActiveSelector = createSelector(
  InvestmentsSelector,
  (investments) => investments.filter((el) => el.active),
);

export const InvestmentSelector = createSelector(
  InvestmentsSelector,
  QuerySelector,
  (investments, query) => {
    const investmentId = query.id;
    const investment = _find(investments, (el) => el.slug === investmentId);

    return investment;
  },
);

const MenuSelector = createSelector(
  InvestmentsActiveSelector,
  SharedMenuSelector,
  (investments, sharedMenu) => {
    let offerMenu = {
      id: 'offer',
      children: investments.map((el) => ({
        id: el.id,
        slug: el.slug,
        value: el.title,
        route: 'investmentDescription',
        fieldInvestmentUpcoming: el.fieldInvestmentUpcoming,
        params: { id: el.slug },
      })),
    };

    const filterMenuItems = _filter(offerMenu.children, (el) => {
      if (!el.fieldInvestmentUpcoming && !config.investmentsExcludedFromMenu.includes(el.id)) {
        return el;
      }
      return false;
    });

    offerMenu = {
      id: 'offer',
      children: filterMenuItems,
    };

    const menu = [...sharedMenu, offerMenu];

    const populatedMenu = populateMenu(menu, investments);

    const footerMenu = {
      offer: [],
    };

    footerMenu.offer = offerMenu.children;

    const startMenu = _find(populatedMenu, (el) => el.id === 'start');

    offerMenu.children.push({
      value: 'Lokale użytkowe',
      route: 'wyszukiwarka',
      params: {
        typeSelected: config.search.typeIds.businessPremises,
      },
    });

    offerMenu.children.push({
      value: 'Wykończenia pod klucz',
      route: 'turnkeyFinishes',
    });

    offerMenu.children.push({
      value: 'Mapa inwestycji',
      route: `/#${config.locationIds.investmentMap}`,
      scroll: false,
    });

    footerMenu.start = startMenu ? startMenu.items : [];

    return {
      menu: populatedMenu,
      footerMenu,
    };
  },
);

const QuickSearchSelector = createSelector(
  itemsSelector,
  (items) => {
    const filters = {
      investmentOptions: [],
    };

    let roomsMin = Number.MAX_SAFE_INTEGER;
    let roomsMax = 0;
    let priceMin = Number.MAX_SAFE_INTEGER;
    let priceMax = 0;
    let surfaceMin = Number.MAX_SAFE_INTEGER;
    let surfaceMax = 0;

    items.forEach((el) => {
      if (
        (el.type.id === config.search.typeIds.flat || el.type.id === config.search.typeIds.studio)
          && el.status.id !== config.search.statusIds.sold
      ) {
        filters.investmentOptions = pushUniqueElement(filters.investmentOptions, el.investment);

        // apparently both rooms.id and rooms.name are number of the rooms
        if (roomsMin > el.rooms.id) {
          roomsMin = el.rooms.id;
        }

        if (roomsMax < el.rooms.id) {
          roomsMax = el.rooms.id;
        }

        if (priceMin > el.price) {
          priceMin = el.price;
        }

        if (priceMax < el.price) {
          priceMax = el.price;
        }

        if (surfaceMin > el.surface) {
          surfaceMin = el.surface;
        }

        if (surfaceMax < el.surface) {
          surfaceMax = el.surface;
        }
      }
    });

    if (priceMax < priceMin) {
      priceMin = 0;
      priceMax = 0;
    }

    if (surfaceMax < surfaceMin) {
      surfaceMin = 0;
      surfaceMax = 0;
    }

    if (roomsMax < roomsMin) {
      roomsMin = 0;
      roomsMax = 0;
    }

    filters.priceOptions = {
      min: Math.floor(priceMin),
      max: Math.ceil(priceMax),
    };

    filters.surfaceOptions = {
      min: Math.floor(surfaceMin),
      max: Math.ceil(surfaceMax),
    };

    filters.roomsOptions = {
      min: Math.floor(roomsMin),
      max: Math.ceil(roomsMax),
    };

    return filters;
  },
);
export default createSelector(
  SharedSelector,
  InvestmentsActiveSelector,
  MenuSelector,
  QuickSearchSelector,
  (shared, investments, menu, quickSearchFilters) => ({
    ...shared,
    investments,
    ...menu,
    quickSearchFilters,
    favouritesCount: getUpdatedCookies(shared),
  }),
);
