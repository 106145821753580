import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: 900;
  display: flex;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
    overflow: hidden;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const LinkStyled = styled.a`
  text-decoration: none;
`;

const ElementWrapper = styled.div`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: initial;
  }
`;

export { Wrapper, LinkStyled, ElementWrapper };
