import { defineMessages } from 'react-intl';

export default defineMessages({
  office: {
    id: 'ContactUsBlock.office',
    defaultMessage: 'Biura Sprzedaży',
  },
  contact: {
    id: 'ContactUsBlock.contact',
    defaultMessage: 'Skontaktuj się',
  },
  us: {
    id: 'ContactUsBlock.us',
    defaultMessage: 'z nami',
  },
});
