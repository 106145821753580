import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import forEach from 'lodash/forEach';

export const SCROLLABLE_ELEMENT_CLASS_NAME = 'scrollable-element';

export function lockBody() {
  const elements = [
    ...document.getElementsByClassName(SCROLLABLE_ELEMENT_CLASS_NAME),
  ];

  forEach(elements, (element) => {
    disableBodyScroll(
      element,
    );
  });
}

export function unlockBody() {
  clearAllBodyScrollLocks();
}
