function trackYouleadCustomEvent(eventName, params) {
  // eslint-disable-next-line no-multi-assign
  const ylData = window.ylData = window.ylData || [];
  let paramsStringified = '';

  // Creates stringified params eg. type=parking&price=12345
  Object.keys(params).forEach((key, index) => { paramsStringified += `${index !== 0 ? '&' : ''}${key}=${params[key].toLowerCase()}`; });
  ylData.push({ event: { eventName, params: paramsStringified } });

  return ylData;
}

export default trackYouleadCustomEvent;
